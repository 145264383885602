import dynamic from 'next/dynamic'
import App from 'next/app'
import { StyledEngineProvider, AscendThemeProvider } from '@achieve/ascend'
import CssBaseline from '@mui/material/CssBaseline'
const Layout = dynamic(() => import('components/Layout'))
import { CacheProvider } from '@emotion/react'
import { createEmotionCache } from 'utils/emotion'
import { AnalyticsProvider } from 'providers/AnalyticsProvider'
import { FeatureFlagProvider } from 'providers/FeatureFlagProvider'
import { DTIProvider } from 'providers/DTIProvider'
import { PhoneDataProvider } from 'providers/PhoneDataProvider'
import getConfig from 'next/config'

import { useParams } from 'next/navigation'
import { useEffect } from 'react'

import './global.css'

import { useRouter } from 'next/router'

// Specific fonts for Achieve.com public site
// import './fonts.scss'

// Global Typography utility classes (not using Ascend/Ascend package)
import './typography.scss'

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

import '@contentful/live-preview/style.css'
import {
  DEFAULT_EXPERIMENT,
  UNASSIGNED_EXPERIMENT,
  getPageVariationByParam,
} from 'constants/experiments'

function MyApp({
  Component,
  emotionCache = clientSideEmotionCache,
  publicProcessDotEnv,
  pageProps = {},
  host,
  hostProtocol,
}) {
  const params = useParams()

  useEffect(() => {
    if (window) {
      let hash = window.location.hash
      if (!hash) {
        return
      } else {
        let anchorIndex = hash.indexOf('#')
        let queryIndex = hash.indexOf('?')
        if (queryIndex > anchorIndex && queryIndex > -1 && anchorIndex > -1) {
          let dividedUrl = hash.split('#')
          let anchorAndQuery = dividedUrl[1].split('?')
          if (router.asPath) {
            router.push(
              `${router.asPath.split('#')[0]}${dividedUrl[0]}?${anchorAndQuery[1]}#${
                anchorAndQuery[0]
              }`
            )
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  const { publicRuntimeConfig } = getConfig()
  const router = useRouter()
  const asPath = router.asPath.split('?')[0]
  const canonicalUrlDefault = `${hostProtocol}${host}${asPath === '/' ? '' : asPath}${
    router?.query?.page > 1 ? '?page=' + router.query.page : ''
  }`

  // Remove unneeded page props from being passed to child components
  const {
    emergencyBanners,
    ffamReferrerContent,
    footer,
    header,
    isFFAMReferrer,
    phoneData,
    specificHeaderCtaData,
    tcbBannerLayout = false,
    // Only pass the remaining props to child components
    ...restPageProps
  } = pageProps
  const pageTitle = `${pageProps?.content?.fields?.pageMetaTitle}${
    router?.query?.page > 1 ? ' page ' + router.query.page : ''
  }`

  //Initialize the quiz name and page
  let quiz_name = ''
  let quiz_page = ''
  const associatedProduct = pageProps?.content?.fields?.associatedProduct ?? 'None'

  let specificHeaderCtaDataFiltered = specificHeaderCtaData?.[associatedProduct]

  // Here We need to get the value of the current experimentation on the page level
  // If the value is not either off or Unassigned We print the correct experiment variation
  // Also on pageVariation We set the variation to handle all the events

  const experimentToggle = router?.query?.v
    ? getPageVariationByParam(router?.query?.v)
    : pageProps?.featureToggleObject?.[pageProps?.content?.fields?.experiment?.featureFlagId]
  let pageVariation =
    experimentToggle === pageProps?.content?.fields?.experimentVariation ||
    (experimentToggle === UNASSIGNED_EXPERIMENT &&
      pageProps?.content?.fields?.experimentVariation === DEFAULT_EXPERIMENT)
      ? {
          id: pageProps?.content?.fields?.experiment?.featureFlagId,
          name: pageProps?.content?.fields?.experiment?.name,
          variation: experimentToggle,
        }
      : null
  let pageSectionsVariations = []
  let pageSectionVariation = false
  pageProps?.content?.fields?.sections?.map((section) => {
    // Get the name of the quiz and the page
    if (section?.fields?.uiComponent === 'DebtQuiz') {
      quiz_name = section?.fields?.name
      quiz_page = `${section?.fields?.name} - Landing Page`
    }
    // Here We need to get the value of the current experimentation on the section level
    // If the value is not either off or Unassigned We print the correct experiment variation
    // Also on pageSectionsVariations We set the variation list to handle all the events for this page section only
    if (section?.fields?.experiment?.featureFlagId) {
      let experimentToggleSection =
        pageProps?.featureToggleObject?.[section?.fields?.experiment?.featureFlagId]

      experimentToggleSection = router?.query?.vs
        ? getPageVariationByParam(router?.query?.vs)
        : experimentToggleSection
      if (router?.query?.vs) {
        pageProps.featureToggleObject[section?.fields?.experiment?.featureFlagId] =
          getPageVariationByParam(router?.query?.vs)
      }
      pageSectionVariation = Boolean(
        section?.fields?.experimentVariation === experimentToggleSection ||
          (section?.fields?.experimentVariation === DEFAULT_EXPERIMENT &&
            experimentToggleSection === UNASSIGNED_EXPERIMENT)
      )
      if (
        section?.fields?.experiment?.pageLevelExperiment &&
        (pageSectionVariation || router?.query?.vs)
      ) {
        //force section level experiment to be page level for all sections
        pageVariation = {
          id: section?.fields?.experiment?.featureFlagId,
          name: section?.fields?.experiment?.name,
          variation: experimentToggleSection,
        }
      } else {
        if (pageSectionVariation) {
          pageSectionsVariations.push({
            id: section?.fields?.experiment?.featureFlagId,
            name: section?.fields?.experiment?.name,
            variation: experimentToggleSection,
          })
        }
      }
    }
  })

  return (
    <FeatureFlagProvider
      featurePathOverrides={pageProps.featurePathOverrides || {}}
      featureToggleObject={pageProps.featureToggleObject || {}}
    >
      <AnalyticsProvider
        config={publicRuntimeConfig}
        pageVariation={pageVariation}
        pageSectionsVariations={pageSectionsVariations}
        loadEventProps={{
          ...(router.pathname === '/learn/[categoryname]/[slug]' && {
            content_type: pageProps?.content?.items?.[0]?.fields.contentType,
          }),
          ...(quiz_name && {
            quiz_name: quiz_name,
            quiz_page: quiz_page,
          }),
        }}
      >
        <CacheProvider value={emotionCache}>
          <StyledEngineProvider injectFirst>
            <AscendThemeProvider>
              <CssBaseline />
              <PhoneDataProvider
                phoneData={phoneData}
                phoneOverride={pageProps?.content?.fields?.phoneOverride}
                associatedProduct={associatedProduct}
              >
                <Layout
                  publicProcessDotEnv={publicProcessDotEnv}
                  metaDescription={pageProps?.content?.fields?.metaDescription}
                  pageMetaData={pageProps?.content?.fields?.pageMetaData}
                  pageMetaTitle={pageTitle}
                  pageTitle={pageProps?.content?.fields?.pageTitle}
                  canonicalLink={
                    pageProps?.content?.fields?.canonicalLinkOverride || canonicalUrlDefault
                  }
                  pageDisclosure={pageProps?.content?.fields?.pageDisclosure}
                  secondaryDisclosures={pageProps?.content?.fields?.secondaryDisclosures}
                  featureToggleObject={pageProps.featureToggleObject}
                  isFFAMReferrer={isFFAMReferrer}
                  isMobileUA={pageProps.isMobile}
                  ffamReferrerContent={ffamReferrerContent}
                  header={{ ...header, siteEnv: publicProcessDotEnv?.NEXT_PUBLIC_SITE_ENVIRONMENT }}
                  footer={footer}
                  emergencyBanners={emergencyBanners || []}
                  pageConfig={pageProps?.content?.fields?.pageConfig}
                  showHeader={!tcbBannerLayout}
                  showFooter={!tcbBannerLayout}
                  alignContentBottom={tcbBannerLayout}
                  consentBanner={pageProps?.achieveConsentBanner?.[0] || []}
                  specificHeaderCtaDataFiltered={specificHeaderCtaDataFiltered}
                  showPhoneNumber={pageProps?.content?.fields?.showPhoneNumber}
                >
                  <DTIProvider>
                    <Component {...restPageProps} />
                  </DTIProvider>
                </Layout>
              </PhoneDataProvider>
            </AscendThemeProvider>
          </StyledEngineProvider>
        </CacheProvider>
      </AnalyticsProvider>
    </FeatureFlagProvider>
  )
}

const getObjectOfPublicEnvs = () => {
  // Get properties from process.env that have keys that start with NEXT_PUBLIC_
  const publicEnvs = Object.entries(process.env).filter(([key]) => key.startsWith('NEXT_PUBLIC_'))
  // Return an object with the keys and values of the public envs
  return publicEnvs.reduce((acc, [key, value]) => {
    acc[key] = value
    return acc
  }, {})
}

// This is required to inject environment variables client side after build time.
// NOTE that this also disables automatic static optimization
MyApp.getInitialProps = async (props) => {
  // Get tealium URL from env var. Typically this would be inlined at build time but we do a single build.
  const publicProcessDotEnv = getObjectOfPublicEnvs()
  const referer = props?.ctx?.req?.headers?.referer
  const initProps = await App.getInitialProps(props)
  const host = props?.ctx?.req?.headers?.host
  const protocol =
    props?.ctx?.req?.headers['x-forwarded-proto'] || props?.ctx?.req?.connection.encrypted
      ? 'https://'
      : 'http://'
  // Return our initial props and our new props
  return { ...initProps, publicProcessDotEnv, referer, host, hostProtocol: protocol }
}

export default MyApp
